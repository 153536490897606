import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash.debounce';
import { Box, CircularProgress, Typography, Icon } from '@mui/material';
import { clearDealPredictAction, fetchDealPredictAction } from '../../../actions/dealPredictAction';
import { FONT_SIZES, FONT_WEIGHTS } from '../../../EatClubTheme';
import { emptyObject, formatCurrency } from '../../../utils/helpers';
import WarningIcon from '../../../assets/warning.svg';
import PredictedResultHelp from './PredictedResultHelp';
import { predictionPropTypes } from '../../../data/models/Prediction';
import useStyles from './PredictedResultsStyles';

const PredictedResults = ({
  clearDealPredict,
  dineIn,
  discount,
  endTime,
  fetchDealPredict,
  lightning,
  predict,
  quantity,
  recurring,
  recurringDays = null,
  restId,
  startDate,
  startTime,
  takeaway,
  userId,
  userType,
}) => {
  const classes = useStyles();
  const { data: prediction, pending } = predict;
  const abortController = useRef();

  const debouncedFetch = useRef(
    debounce(
      (
        dineIn,
        discount,
        endTime,
        lightning,
        quantity,
        recurring,
        recurringDays,
        restId,
        startDate,
        startTime,
        takeaway,
        userId,
        userType,
        dismounting,
      ) => {
        if (dismounting) {
          return;
        }

        const controller = new window.AbortController();
        abortController.current = controller;

        fetchDealPredict({
          dineIn,
          discount,
          endTime,
          lightning,
          quantity,
          recurring,
          recurringDays,
          restId,
          startDate,
          startTime,
          takeaway,
          userId,
          userType,
          controllerSignal: abortController.current.signal,
        });
      },
      500,
    ),
  );

  const abortLatest = () => abortController.current && abortController.current.abort();

  // on dismount
  useEffect(
    () => () => {
      clearDealPredict();

      abortLatest();
      debouncedFetch.current(
        dineIn,
        discount,
        endTime,
        lightning,
        quantity,
        recurring,
        recurringDays,
        restId,
        startDate,
        startTime,
        takeaway,
        userId,
        userType,
        true,
      );
    },
    [],
  );

  useEffect(() => {
    if (!emptyObject(prediction)) {
      clearDealPredict();
    }

    abortLatest();
    debouncedFetch.current(
      dineIn,
      discount,
      endTime,
      lightning,
      quantity,
      recurring,
      recurringDays,
      restId,
      startDate,
      startTime,
      takeaway,
      userId,
      userType,
    );
  }, [
    dineIn,
    discount,
    endTime,
    lightning,
    quantity,
    recurring,
    recurringDays,
    restId,
    startDate,
    startTime,
    takeaway,
    userId,
    userType,
  ]);

  return (
    <Box
      style={{
        backgroundColor: '#EFE8DE',
        padding: '20px',
        maxWidth: '800px',
        marginTop: '40px',
        fontSize: FONT_SIZES.L,
        fontWeight: FONT_WEIGHTS.REGULAR,
        lineHeight: '1.75',
        borderRadius: '32px',
      }}
    >
      {pending || emptyObject(prediction) ? (
        <Box display='flex' alignItems='center'>
          <CircularProgress color='inherit' size={22} style={{ marginRight: '1rem' }} />
          <Typography>Predicting results</Typography>
        </Box>
      ) : (
        <Box>
          <Box
            display='flex'
            gap='30px'
            style={{
              borderRadius: '4px',
              minHeight: '24px',
            }}
          >
            <Box className={classes.predictedResultsContainer}>
              <Box className={classes.predictedResultsHeader}>
                <Box style={{ fontWeight: 500 }}>Predicted results</Box>
                <Box mt='3px'>
                  <PredictedResultHelp />
                </Box>
              </Box>
              <Box>
                <Box display='flex' justifyContent='space-between' width='100%' mb='10px'>
                  <Box>Customers</Box>
                  <Box>{prediction?.prediction}</Box>
                </Box>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <Box>Revenue</Box>
                  <Box>{formatCurrency(prediction?.targetVal, false)}</Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {prediction?.qtyCapped && (
            <Box mt={4} display='flex' flexDirection='row' alignItems='center'>
              <Icon style={{ marginRight: '8px' }}>
                <img src={WarningIcon} width='100%' height='100%' alt='Warning icon' />
              </Icon>
              <Typography>{prediction.qtyCapped}</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

PredictedResults.propTypes = {
  clearDealPredict: PropTypes.func.isRequired,
  dineIn: PropTypes.bool.isRequired,
  discount: PropTypes.string.isRequired,
  endTime: PropTypes.number.isRequired,
  fetchDealPredict: PropTypes.func.isRequired,
  lightning: PropTypes.bool.isRequired,
  predict: predictionPropTypes.isRequired,
  quantity: PropTypes.number.isRequired,
  recurring: PropTypes.bool.isRequired,
  recurringDays: PropTypes.string,
  restId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired,
  takeaway: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  predict: state.dealPredict,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { clearDealPredict: clearDealPredictAction, fetchDealPredict: fetchDealPredictAction },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PredictedResults);

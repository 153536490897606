import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, matchPath } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Drawer } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LiveBookingsHeader } from '@eatclub-apps/ec-bookings-library';
import { Box } from '@eatclub-apps/ec-component-library';
import AppHeader from '../components/layout/appHeader/AppHeader';
import CustomHeader from '../components/layout/appHeader/CustomHeader';
import NavigationDrawer from '../components/layout/navigation/NavigationDrawer';
import { restaurantActivePropTypes } from '../data/models/Restaurant';
import routes from '../routes/Routes';
import useStyles from './AppLayoutStyles';
import { canEditAll, isEmpty } from '../utils/helpers';

const AppLayout = ({
  platform = '',
  goalsEnabled = true,
  obeeEnabled = false,
  premium = false,
  children,
  ecPayEnabled = false,
  restId = null,
  restaurantActive,
  restaurantsFetching = true,
  user,
}) => {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();

  const premiumLabel = (name) => (
    <div className={classes.flex}>
      <div>{name}</div>
      <div className={classes.label}>Premium</div>
    </div>
  );

  const getNavigationItems = () => {
    const newNavigationItems = [
      ...(restaurantsFetching && ecPayEnabled === false
        ? [
            {
              name: 'Analytics',
              slug: 'analytics',
              section: 1,
            },
          ]
        : [
            {
              name: 'Insights',
              slug: 'insights',
              section: 1,
              // isNew: localStorage.getItem('viewedInsights') !== 'true',
              isNew: true,
            },
          ]),
      {
        name: 'Offers',
        slug: 'offers',
        section: 1,
      },
      {
        name: 'Transactions',
        slug: 'transactions',
        section: 1,
      },
      ...(!ecPayEnabled
        ? [
            {
              name: 'Billing & Invoices',
              slug: 'billing',
              section: 1,
            },
          ]
        : [
            {
              name: 'Settlements',
              slug: 'settlements',
              section: 1,
            },
          ]),
      {
        name: 'Menus',
        slug: 'menus',
        section: 1,
      },
      // TODO fix a bug where hasCompletedInitialLoad is always false for staff logins
      obeeEnabled
        ? {
            name: 'Bookings',
            section: 1,
            subItems: [
              {
                name: 'Run Sheet',
                slug: 'livebookings/runsheet',
              },
              {
                name: premium ? 'Floor Plan' : premiumLabel('Floor Plan'),
                slug: 'livebookings/floorplan',
              },
              {
                name: premium ? 'Timeline' : premiumLabel('Timeline'),
                slug: 'livebookings/timeline',
              },
              {
                name: 'Monthly',
                slug: 'livebookings/monthly',
              },
              {
                name: premium ? 'Guest Book' : premiumLabel('Guest Book'),
                slug: 'livebookings/guestbook',
              },
              {
                name: 'Settings',
                slug: 'livebookings/settings',
              },
            ],
          }
        : {
            name: 'Bookings',
            slug: 'livebookings',
            section: 1,
          },
      { name: 'Support', slug: 'support', section: 2 },
    ];

    if (platform === 'whitelabel') {
      const dealIndex = newNavigationItems.findIndex((nav) => nav.name === 'Goals');
      newNavigationItems.splice(dealIndex, 1);
    } else {
      // For restaurants which do not yet have goals enabled
      // eslint-disable-next-line no-lonely-if
      if (goalsEnabled === false) {
        const dealIndex = newNavigationItems.findIndex((nav) => nav.name === 'Offers');
        newNavigationItems[dealIndex] = { name: 'Deals', slug: 'deals', section: 1 };
      }
    }

    return newNavigationItems;
  };

  const [navigationItems, setNavigationItems] = useState(getNavigationItems());
  const [showDrawer, setShowDrawer] = useState(false); // temporary nav and tablet/mobile nav

  const route = routes.find((routeToFind) => matchPath(location.pathname, routeToFind));

  useEffect(() => {
    setNavigationItems(getNavigationItems());
  }, [goalsEnabled, obeeEnabled, ecPayEnabled, premium]);

  // Set title and header
  useEffect(() => {
    const pageTitle = route?.pageTitle || 'Dashboard';
    const titleDelimiter = pageTitle !== '' ? ' | ' : '';
    const restaurantName = restaurantActive?.restaurant?.name;
    const titleEnd = `${import.meta.env.VITE_WEBSITE_NAME} Partners`;
    // window.document.title = `${pageTitle}${titleDelimiter}${titleEnd}`;
    window.document.title = [pageTitle, restaurantName, titleEnd]
      ?.filter((i) => !!i)
      ?.join(titleDelimiter);
  }, [location.pathname, navigationItems, restaurantActive?.restaurant?.name]);

  const handleShowDrawer = () => {
    setShowDrawer(true);
  };

  const hideDrawer = () => {
    setShowDrawer(false);
  };

  const breakpointMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const allowHideNavigation =
    route?.hideNavigation ||
    (route?.header === 'obee' && ((route?.premiumRequired && premium) || !route?.premiumRequired)); // Whether this screen lets the side bar collapse

  // Logic for whether we are showing the navigation
  // prettier-ignore
  const isOpenDesktop = route?.forceHideNavigation
    ? false
    : allowHideNavigation
    ? showDrawer
    : true; // Ensure we always show the sidebar on non-obee screens

  // TODO this is a better method of checking initial load of restaurant:
  const loadingRestaurant =
    restaurantsFetching || restaurantActive.restaurantFetching || restaurantActive.fetching;

  // Force it to show if a staff account has not selected a restaurant
  const [forceShow, setForceShow] = useState(false);

  // Ensure that if a staff user has not selected a restaurant, we show the restaurant selector no matter what
  useEffect(() => {
    if (!loadingRestaurant && isEmpty(restaurantActive.restaurant)) {
      setForceShow(true);
    } else {
      setForceShow(false);
    }
  }, [restaurantActive]);

  const isOpen = (breakpointMdDown ? showDrawer : isOpenDesktop) || forceShow; // force open if no restaurant is active for staff logins

  const renderHeader = () => {
    // If restaurant is not enabled, don't show the page (admins can still access)
    if (
      !canEditAll(user) &&
      restaurantActive?.restaurant?.objectId &&
      !restaurantActive?.restaurant?.enabled
    ) {
      return (
        <AppHeader showDrawer={handleShowDrawer} forceShowHeader>
          <Box>
            This account is currently disabled. Please contact support via{' '}
            <a href='mailto:hello@eatclub.com.au' target='_blank' rel='noreferrer'>
              hello@eatclub.com.au
            </a>{' '}
            if you wish to have the account re-enabled.
          </Box>
        </AppHeader>
      );
    }

    if (!((route?.premiumRequired && premium) || !route?.premiumRequired)) {
      return <AppHeader showDrawer={handleShowDrawer}>{children}</AppHeader>;
    }

    // TODO replace this with something else, but ideally don't render ec bookings until a rest ID is set
    // TODO also handle if you're staff and no restaurant is set
    // TODO can optimize further if restaurant id is stored in local state
    if (!restId) {
      return <Box />;
    }

    switch (route?.header) {
      case 'obee':
        return (
          <LiveBookingsHeader
            showNavigation={handleShowDrawer}
            navigationOpen={isOpen}
            pageId={route?.id}
          >
            {children}
          </LiveBookingsHeader>
        );
      case 'custom':
        return <CustomHeader navigationOpen={isOpenDesktop}>{children}</CustomHeader>;
      default:
        return <AppHeader showDrawer={handleShowDrawer}>{children}</AppHeader>;
    }
  };

  return (
    <>
      <nav className={classes.drawer}>
        <Drawer
          variant={
            !forceShow && (breakpointMdDown || allowHideNavigation) ? 'temporary' : 'persistent'
          }
          open={isOpen}
          onClose={hideDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile
            onBackdropClick: hideDrawer,
          }}
        >
          <NavigationDrawer
            hideDrawer={hideDrawer}
            navigationItems={navigationItems}
            hideNavigation={hideDrawer}
            allowHideNavigation={allowHideNavigation}
          />
        </Drawer>
      </nav>
      {restId && renderHeader()}
    </>
  );
};

AppLayout.propTypes = {
  platform: PropTypes.string,
  goalsEnabled: PropTypes.bool,
  obeeEnabled: PropTypes.bool,
  premium: PropTypes.bool,
  ecPayEnabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  restId: PropTypes.string,
  restaurantActive: restaurantActivePropTypes.isRequired,
  restaurantsFetching: PropTypes.bool,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  platform: state.restaurantActive.restaurant?.platform,
  goalsEnabled: state.restaurantActive.restaurant?.offersEnabled,
  obeeEnabled: state.restaurantActive.restaurant?.obeeEnabled,
  restaurantActive: state.restaurantActive,
  restaurantsFetching: state.restaurants.fetching,
  premium: state.restaurantActive.restaurant?.premium,
  ecPayEnabled: state.restaurantActive.restaurant?.ecPayEnabled,
  restId: state.restaurantActive.restaurant.objectId,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);

import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { disableDealForDate, reenableDealForDate } from '../graphql/mutations';
import { devLog } from '../utils';

export const disableDealAction = (dealId, date, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'DISABLE_DEAL',
  });
  dispatch({
    type: type.DISABLE_DEAL_PENDING,
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: disableDealForDate,
          variables: {
            dealId,
            date,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'disable deal', response.data.disableDealForDate);

      dispatch({
        type: type.DISABLE_DEAL_SUCCESS,
        payload: { disabledDeal: response.data.disableDealForDate },
      });

      dispatch({
        type: type.ADD_MESSAGE,
        payload: {
          id: `DISABLE_DEAL_SUCCESS_${new Date().getTime()}`,
          message: `Success, the deal has been disabled`,
          severity: 'success',
        },
      });
    } catch (error) {
      devLog('error', 'disable deal', error);

      dispatch({
        type: type.DISABLE_DEAL_FAILURE,
        payload: `Unable to disable deal: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'DISABLE_DEAL',
      });
    }
  })();
};

export const enableDealAction = (dealId, date, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'ENABLE_DEAL',
  });
  dispatch({
    type: type.ENABLE_DEAL_PENDING,
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: reenableDealForDate,
          variables: {
            dealId,
            date,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      devLog('success', 'enable deal', response.data.reenableDealForDate);

      dispatch({
        type: type.ENABLE_DEAL_SUCCESS,
        payload: { enabledDeal: response.data.reenableDealForDate },
      });

      dispatch({
        type: type.ADD_MESSAGE,
        payload: {
          id: `ENABLE_DEAL_SUCCESS_${new Date().getTime()}`,
          message: `Success, the deal has been enabled`,
          severity: 'success',
        },
      });
    } catch (error) {
      devLog('error', 'enable deal', error);

      dispatch({
        type: type.ENABLE_DEAL_FAILURE,
        payload: `Unable to enable deal: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'ENABLE_DEAL',
      });
    }
  })();
};

export const clearDisableDealAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_DISABLE_DEAL,
  });
};

/* eslint-disable no-shadow */
/* eslint-disable radix */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { increaseDealPercentAction } from '../../actions/dealIncreaseAction';
import { discountsAllDay, discountsTimeLimited } from '../../constants';

const DealIncreasePercentDialog = ({
  deal,
  increaseDealPercentAction,
  openDialog,
  restId,
  setOpenDialog,
  userId,
  userType,
}) => {
  let options = deal.lightning
    ? Array.from(discountsTimeLimited).reverse()
    : Array.from(discountsAllDay).reverse();

  options = options.map((option) => {
    const optionNum = parseInt(option.substr(0, 2));
    const dealNum = parseInt(deal.discount.substr(0, 2));
    if (optionNum <= dealNum) {
      return {
        percent: option,
        disabled: true,
      };
    }

    return {
      percent: option,
      disabled: false,
    };
  });

  const [newDiscount, setNewDiscount] = useState(deal.discount);
  const [newDiscountError, setNewDiscountError] = useState('');

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const validateForm = () => {
    let formErrors = false;

    if (newDiscount === deal.discount) {
      setNewDiscountError('Please select a new discount');
      formErrors = true;
    } else if (newDiscountError) {
      setNewDiscountError('');
    }

    return formErrors;
  };

  const submit = (event) => {
    event.preventDefault();

    const hasErrors = validateForm();

    if (hasErrors) {
      return;
    }

    increaseDealPercentAction(deal.dealId, newDiscount, restId, userId, userType);
    closeDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      TransitionProps={{
        onEnter: () => {
          setNewDiscount(deal.discount);
        },
        onExit: () => {},
      }}
    >
      <DialogTitle>Increase {deal.discount} Deal Percent</DialogTitle>
      <DialogContent>
        <form id='deal-percent-form' noValidate autoComplete='off' onSubmit={submit}>
          <FormControl fullWidth error={Boolean(newDiscountError)}>
            <InputLabel id='discount-select' style={{ backgroundColor: '#fff' }}>
              Deal Offered
            </InputLabel>
            <Select
              labelId='discount-select'
              value={newDiscount}
              onChange={(e) => setNewDiscount(e.target.value)}
            >
              {options.map((option, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={index} value={option.percent} disabled={option.disabled}>
                  {option.percent}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{newDiscountError}</FormHelperText>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} variant='outlined' color='secondary'>
          Cancel
        </Button>
        <Button form='deal-percent-form' onClick={submit} type='submit' autoFocus>
          Increase Percent
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ increaseDealPercentAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DealIncreasePercentDialog);

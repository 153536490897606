/* eslint-disable */
import React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Dialog } from '@mui/material';
import { Link } from 'react-router-dom';
import { Alert, AlertTitle } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

const DealEditDialog = ({ deal, openDialog, setOpenDialog }) => {
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      TransitionProps={{
        onEnter: () => {},
        onExit: () => {},
      }}
    >
      <DialogTitle>Edit {deal.discount} Deal</DialogTitle>

      <DialogContent>
        <Alert icon={<HelpOutline />} variant='filled' severity='error'>
          <AlertTitle>Unable to Edit deal</AlertTitle>
          This deal was created by the EatClub platform, please contact your account manager to make
          any changes.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='outlined' color='secondary'>
          Close
        </Button>
        <Button component={Link} to='/support?discussion=3'>
          Contact Account Manager
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DealEditDialog;

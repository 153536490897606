import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  predictedResultsContainer: {
    maxWidth: '300px',
    width: '100%',
  },
  predictedResultsHeader: {
    display: 'inline-flex',
    marginBottom: '10px',
    gap: '10px',
    alignItems: 'center',
  },
}));

export default useStyles;

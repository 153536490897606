import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box, Spacer } from '@eatclub-apps/ec-component-library';
import { getPercent } from '../../data/models/Analytics';
import InsightPill from './InsightPill';
import useStyles from './InsightSummaryStyles';
import PlusIcon from '../../assets/icons/plus.svg';

const InsightItem = ({
  Icon,
  iconColor,
  title,
  data = null,
  previous = null,
  dataFormatted = null,
  previousFormatted = null,
  pastYear = null,
  loading,
  onClick,
}) => {
  const theme = useTheme();
  const classes = useStyles({ iconColor, loading });

  const change = getPercent(data, previous);

  return (
    <Spacer className={classes.gridItem} onClick={onClick}>
      <PlusIcon className={classes.plusIcon} />
      <Box className={classes.iconCircle} onClick={onClick}>
        <Icon />
      </Box>
      <Box className={classes.details}>
        <Box className={classes.title}>{title}</Box>

        {loading && (
          <Box
            style={{
              height: '101px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              paddingLeft: '20px',
              color: theme.colors.cloud,
            }}
          >
            <CircularProgress color='inherit' />
          </Box>
        )}

        <Box className={classes.insightDataContainer}>
          <Box className={classes.insightData}>{dataFormatted}</Box>
          <InsightPill
            increase={change}
            change={data > previous ? 'gain' : data < previous ? 'loss' : ''}
            value={previousFormatted}
            loading={loading}
            variant='ghost'
          />
          <InsightPill label='12 months' showChange={false} value={pastYear} loading={loading} />
        </Box>
      </Box>
    </Spacer>
  );
};

InsightItem.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired, // svgs are functional components
  iconColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  previous: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  previousFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pastYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.loading?.loadingIds.length > 0,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InsightItem);

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  disableOfferForDateAction,
  deleteOfferAction,
  reEnableOfferForDateAction,
} from '../../../actions/offersForGoalAction';
import { calculateStatusForDeal } from '../../../data/models/Deal';
import { COLORS } from '../../../EatClubTheme';
import { offerPropTypes } from '../../../reducers/generatedOffersReducer';
import { trackEvent } from '../../../utils/analytics';
import {
  formatCurrency,
  getDayOfWeek,
  pluralise,
  eatClubDayOfWeekToDayjs,
  canEditAll,
  isEmpty,
} from '../../../utils/helpers';
import ActionMenu from '../ActionMenu/ActionMenu';
import SmallCheck from '../../../assets/small_check.svg';
import Bin from '../../../assets/bin.svg';
import Pencil from '../../../assets/pencil.svg';
import Disabled from '../../../assets/disabled.svg';
import DeleteOfferModal from '../Modals/DeleteOfferModal';
import DisableOfferModal from '../Modals/DisableOfferModal';
import useStyles from './OfferCardStyles';
import RequestEditOfferModal from '../Modals/RequestEditOfferModal';
import RequestDeleteOfferModal from '../Modals/RequestDeleteOfferModal';
import {
  requestOfferDeleteAction,
  requestOfferEditAction,
} from '../../../actions/requestCallAction';
import { Text } from '../../Text';
import DineInSVG from '../../../assets/icons/dine_in.svg';
import TakeawaySVG from '../../../assets/icons/takeaway.svg';
import GoalsSVG from '../../../assets/icons/goals.svg';

const OfferCard = ({
  offer,
  disableOfferForDate,
  reEnableOfferForDateAction,
  deleteOfferAction,
  restId,
  userId,
  userType,
  occurrences = 0,
  requestOfferDelete,
  requestOfferEdit,
}) => {
  // TODO handle disableForDate and disableUntilDate
  const history = useHistory();
  const muiTheme = useTheme();
  const breakpointXsDown = useMediaQuery(muiTheme.breakpoints.down('xs'));
  const classes = useStyles({ status: offer?.status });

  const [disablingOffer, setDisablingOffer] = useState(false);
  const [deletingOffer, setDeletingOffer] = useState(false);
  const [requestEditingOffer, setRequestEditingOffer] = useState(false);
  const [requestDeletingOffer, setRequestDeletingOffer] = useState(false);
  const [actions, setActions] = useState([]);

  const belongsToGoal = offer?.goalId;
  const originIsEatClub = offer?.deals?.filter((deal) => deal?.origin === 'eatclub').length > 0;
  const canEditOffer = !originIsEatClub || canEditAll(userType);

  const getOfferDate = () => {
    const days = (7 + eatClubDayOfWeekToDayjs(offer?.dayOfWeek) - getDayOfWeek(dayjs())) % 7;
    return dayjs().add(days, 'days');
  };

  const getDineInDeal = () => {
    const dineInDeals = offer?.deals?.filter((offerToCheck) => !offerToCheck?.takeawayOnly);

    // TODO an offer can have many deals, e.g. future ones. Make sure we are getting the right one
    if (dineInDeals?.length > 0) {
      return dineInDeals[0];
    }

    return null;
  };

  const getTakeawayDeal = () => {
    const takeawayDeals = offer?.deals?.filter((offerToCheck) => !offerToCheck?.dineInOnly);

    // TODO an offer can have many deals, e.g. future ones. Make sure we are getting the right one
    if (takeawayDeals?.length > 0) {
      return takeawayDeals[0];
    }

    return null;
  };

  const hasDineIn = () =>
    offer?.deals?.filter((offerToCheck) => !offerToCheck?.takeawayOnly).length > 0;

  const hasTakeaway = () =>
    offer?.deals?.filter((offerToCheck) => !offerToCheck?.dineInOnly).length > 0;

  const enableOffer = (date) => {
    reEnableOfferForDateAction(
      offer?.deals?.map((deal) => deal?.objectId),
      date,
      offer?.id,
      restId,
      userId,
      userType,
    );
  };

  const disableOffer = (disableReason, typeToDisable) => {
    const date = getOfferDate().format('YYYY-MM-DD'); // TODO get as restaurant's timezone

    let dealsToDisable = offer?.deals || [];
    if (typeToDisable === 'dineInOnly') {
      dealsToDisable = offer?.deals?.filter((offerToCheck) => !offerToCheck?.takeawayOnly);
    } else if (typeToDisable === 'takeawayOnly') {
      dealsToDisable = offer?.deals?.filter((offerToCheck) => !offerToCheck?.dineInOnly);
    }

    disableOfferForDate(
      dealsToDisable.map((deal) => deal?.objectId),
      date,
      offer?.goalId,
      offer?.goalOfferId,
      disableReason || '',
      '',
      offer?.id,
      restId,
      userId,
      userType,
    );
    setDisablingOffer(false); // TODO this should be set in redux
  };

  const deleteOffer = (deleteReason, deleteReasonMessage, allOccurrences, typeToDelete) => {
    let deals = offer?.deals;

    if (typeToDelete === 'takeaway') {
      deals = deals?.filter((deal) => deal.takeawayOnly === true);
    } else if (typeToDelete === 'dineIn') {
      deals = deals?.filter((deal) => deal.dineInOnly === true);
    }

    deleteOfferAction(
      deals?.map((deal) => deal?.objectId),
      offer?.goalId,
      offer?.goalOfferId,
      deleteReason,
      deleteReasonMessage,
      offer?.id,
      restId,
      userId,
      userType,
      allOccurrences,
    );
    setDeletingOffer(false);
  };

  const requestEditOffer = (reason, reasonMessage, contact, phone) => {
    trackEvent('button_click: confirm_edit_offer_request');

    requestOfferEdit(
      restId,
      userId,
      userType,
      offer?.deals?.map((deal) => deal?.objectId),
      reason,
      reasonMessage,
      contact,
      phone,
    );
    setRequestEditingOffer(false);
  };

  const requestDeleteOffer = (reason, reasonMessage, contact, phone) => {
    trackEvent('button_click: confirm_delete_offer_request');

    requestOfferDelete(
      restId,
      userId,
      userType,
      offer?.deals?.map((deal) => deal?.objectId),
      reason,
      reasonMessage,
      contact,
      phone,
    );
    setRequestDeletingOffer(false);
  };

  useEffect(() => {
    const dayOfWeek = getDayOfWeek(dayjs());

    setActions([
      {
        name: 'Edit',
        icon: <Pencil />,
        type: 'button',
        onClick: !canEditOffer
          ? () => {
              trackEvent(`button_click: edit_offer_admin_origin`);
              setRequestEditingOffer(true);
            }
          : () => {
              trackEvent(`button_click: edit_offer_partner_origin`);
              history.push(`/offers/edit/${offer?.id}`);
            },
      },
      // Only show disable for today on deals that are running today
      ...(eatClubDayOfWeekToDayjs(offer?.dayOfWeek) === dayOfWeek && offer?.status !== 'disabled'
        ? [
            {
              name: 'Disable for today',
              icon: <Disabled />,
              type: 'button',
              onClick: () => {
                trackEvent(`button_click: disable_offer`);
                setDisablingOffer(true);
              },
            },
          ]
        : []),
      // Allow re-enabling offer if one or more of its deals are disabled for the day
      ...(offer?.status === 'disabled' ||
      offer?.deals?.some((deal) => !isEmpty(deal.disabledForDate))
        ? [
            {
              name: 'Enable',
              icon: <Disabled />,
              type: 'button',
              onClick: () => {
                trackEvent(`button_click: enable_offer`);
                enableOffer(getOfferDate().format('YYYY-MM-DD'));
              },
            },
          ]
        : []),
      {
        type: 'divider',
      },
      {
        name: 'Delete',
        icon: (
          <Box pt='3px'>
            <Bin />
          </Box>
        ),
        type: 'button',
        onClick: !canEditOffer
          ? () => {
              trackEvent(`button_click: edit_offer_admin_origin`);
              setRequestDeletingOffer(true);
            }
          : () => {
              trackEvent(`button_click: edit_offer_partner_origin`);
              setDeletingOffer(true);
            },
      },
    ]);
  }, [offer]);

  const getAmountRelativeToGoal = (amount, goalAmount) => {
    // Avoid division by zero
    if (goalAmount <= 0) {
      return '';
    }

    if (Math.round(amount) === Math.round(goalAmount)) {
      return 'Perfectly on target';
    }

    const percentage = Math.round((amount / goalAmount) * 100);

    if (amount < goalAmount) {
      return `${100 - percentage}% below ${formatCurrency(goalAmount, false)}`;
    }

    return `${percentage - 100}% above ${formatCurrency(goalAmount, false)}`;
  };

  const getCustomers = () => {
    if (!belongsToGoal) {
      return <></>;
    }

    // Cancelled offers don't show data
    if (offer?.status === 'cancelled') {
      return <Text size='sm'>N/A</Text>;
    }

    const getBookingLabel = () => {
      if (offer?.partySizeLower === offer?.partySizeUpper) {
        return Math.max(1, offer?.partySizeLower);
      }

      return `${Math.max(1, offer?.partySizeLower)} - ${Math.max(2, offer?.partySizeUpper)}`;
    };

    // Show a prediction if we should
    if (offer?.status !== 'complete') {
      return (
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px', whiteSpace: 'nowrap' }}>
          <Text size='sm'>Customers</Text>
          <Text size='sm'>
            {getBookingLabel()}{' '}
            <Text size='xs' style={{ color: '#7C7C7C' }}>
              Predicted
            </Text>
          </Text>
        </Box>
      );
    }

    // Otherwise, show the actual result
    return (
      <Text size='sm'>
        {offer?.partySizeActual} {pluralise('Customer', offer?.partySizeActual)}
      </Text>
    );
  };

  const getRevenue = () => {
    if (!belongsToGoal) {
      return <></>;
    }

    // Cancelled offers don't show data
    if (offer?.status === 'cancelled') {
      return <Text size='sm'>N/A</Text>;
    }

    // Show a prediction if we should
    if (offer?.status !== 'complete') {
      return (
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px', whiteSpace: 'nowrap' }}>
          <Text size='sm'>Revenue</Text>
          <Text size='sm'>
            {formatCurrency(offer?.partySizeUpper !== 0 ? offer?.revenueExpected : 0, false)}{' '}
            <Text size='xs' style={{ color: '#7C7C7C' }}>
              Predicted
            </Text>
          </Text>
        </Box>
      );
    }

    // Otherwise, show the actual result
    return <Text size='sm'>{formatCurrency(offer?.revenueActual, false)} Revenue</Text>;
  };

  const getRepeatText = () => {
    if (!offer?.recurring) {
      return 'One-off';
    }

    // Don't try to show occurrences if it doesn't belong to a goal
    if (!belongsToGoal) {
      return 'Repeats weekly';
    }

    return `Repeats ${
      occurrences > 0 ? `${occurrences} ${pluralise('time', occurrences)}` : 'weekly'
    }`;
  };

  const dineInStatus = calculateStatusForDeal(getDineInDeal(), getOfferDate());
  const takeawayStatus = calculateStatusForDeal(getTakeawayDeal(), getOfferDate());

  const getNumberOfDineInDeals = () => {
    const dineInDeal = getDineInDeal();

    if (!dineInDeal.recurring || dineInStatus === 'active') {
      return dineInDeal.qtyLeft;
    }

    return dineInDeal.recurringQty;
  };

  const getNumberOfTakeawayDeals = () => {
    const takeawayDeal = getTakeawayDeal();

    if (!takeawayDeal.recurring || takeawayStatus === 'active') {
      return takeawayDeal.qtyLeft;
    }

    return takeawayDeal.recurringQty;
  };

  const repeatText = getRepeatText();

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.gridContainer}>
          <Box>
            <Box className={classes.subHeading}>
              {!offer?.lightning
                ? 'All day'
                : `From ${offer?.startTimeReadable} - ${offer?.endTimeReadable}`}
            </Box>

            <Box style={{ marginTop: '8px', display: 'flex', gap: '16px' }}>
              <Box className={classes.detail}>
                <SmallCheck height={12} width={16} />
                <Text size='sm'>{offer?.discount}</Text>
              </Box>

              <Box className={classes.detail}>
                <SmallCheck height={12} width={16} />
                <Text size='sm'>{repeatText}</Text>
              </Box>
            </Box>
          </Box>

          <Box className={classes.detailsContainer}>
            <Box className={classes.details}>
              {hasDineIn() && (
                <Box style={{ display: 'inline-flex', gap: '8px', alignItems: 'center' }}>
                  <Box style={{ position: 'relative' }}>
                    <Box style={{ display: 'flex' }}>
                      <DineInSVG />
                    </Box>

                    {dineInStatus === 'disabled' && (
                      <Disabled
                        title='disabled for this date'
                        style={{
                          position: 'absolute',
                          color: COLORS.DISABLED,
                          width: '100%',
                          height: '100%',
                          top: 0,
                          left: 0,
                        }}
                      />
                    )}
                  </Box>

                  {dineInStatus !== 'disabled' && (
                    <Box style={{ whiteSpace: 'nowrap' }}>{getNumberOfDineInDeals()} left</Box>
                  )}
                </Box>
              )}

              {hasTakeaway() && (
                <Box style={{ display: 'inline-flex', gap: '8px', alignItems: 'center' }}>
                  <Box style={{ position: 'relative' }}>
                    <Box style={{ display: 'flex' }}>
                      <TakeawaySVG />
                    </Box>

                    {takeawayStatus === 'disabled' && (
                      <Disabled
                        title='disabled for this date'
                        style={{
                          position: 'absolute',
                          color: COLORS.DISABLED,
                          width: '100%',
                          height: '100%',
                          top: 0,
                          left: 0,
                        }}
                      />
                    )}
                  </Box>

                  {takeawayStatus !== 'disabled' && (
                    <Box style={{ whiteSpace: 'nowrap' }}>{getNumberOfTakeawayDeals()} left</Box>
                  )}
                </Box>
              )}

              {belongsToGoal && (
                <Box>
                  <GoalsSVG />
                </Box>
              )}
            </Box>

            {belongsToGoal && (
              <Box className={classes.predictionsContainer}>
                <Box style={{ display: 'flex', gap: '40px' }}>
                  {getCustomers()}
                  {getRevenue()}
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Box>
          {actions?.length > 0 && (
            <ActionMenu options={actions} disabled={offer?.status === 'cancelled'} />
          )}
        </Box>
      </Box>

      <DisableOfferModal
        isOpen={disablingOffer}
        onClose={() => setDisablingOffer(false)}
        offer={offer}
        onSave={disableOffer}
      />
      <DeleteOfferModal
        isOpen={deletingOffer}
        onClose={() => setDeletingOffer(false)}
        offer={offer}
        onSave={deleteOffer}
      />
      <RequestEditOfferModal
        isOpen={requestEditingOffer}
        onClose={() => setRequestEditingOffer(false)}
        offer={offer}
        onSave={requestEditOffer}
      />
      <RequestDeleteOfferModal
        isOpen={requestDeletingOffer}
        onClose={() => setRequestDeletingOffer(false)}
        offer={offer}
        onSave={requestDeleteOffer}
      />
    </>
  );
};

OfferCard.propTypes = {
  offer: offerPropTypes.isRequired,
  disableOfferForDate: PropTypes.func.isRequired,
  reEnableOfferForDateAction: PropTypes.func.isRequired,
  deleteOfferAction: PropTypes.func.isRequired,
  requestOfferDelete: PropTypes.func.isRequired,
  requestOfferEdit: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  restId: PropTypes.string.isRequired,
  occurrences: PropTypes.number,
};

const mapStateToProps = (state) => ({
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      disableOfferForDate: disableOfferForDateAction,
      reEnableOfferForDateAction,
      deleteOfferAction,
      requestOfferDelete: requestOfferDeleteAction,
      requestOfferEdit: requestOfferEditAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OfferCard);

import { API } from 'aws-amplify';
import { getAppVersion } from '../utils/helpers';
import * as type from './types';
import { deleteDeal } from '../graphql/mutations';
import { devLog } from '../utils';

export const deleteDealAction = (dealId, restId, userId, userType) => (dispatch) => {
  dispatch({
    type: type.SET_ID_APP_LOADING,
    payload: 'DELETE_DEAL',
  });
  dispatch({
    type: type.DELETE_DEAL_PENDING,
  });

  (async () => {
    try {
      const response = await API.graphql(
        {
          query: deleteDeal,
          variables: {
            dealId,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        },
        {
          'user-id': userId,
          'user-type': userType,
          'rest-id': restId,
          'app-version': getAppVersion(),
        },
      ).catch((error) => {
        throw new Error(error.errors[0].message);
      });

      const parsedResponse = JSON.parse(response.data.deleteDeal);
      if (parsedResponse.status !== 'success') {
        throw new Error('Response error');
      }

      devLog('success', 'delete deal', response.data.deleteDeal);

      dispatch({
        type: type.DELETE_DEAL_SUCCESS,
        payload: { deletedDeal: { objectId: dealId } },
      });
    } catch (error) {
      devLog('error', 'delete deal', error);

      dispatch({
        type: type.DELETE_DEAL_FAILURE,
        payload: `Unable to delete deal: ${error}`,
      });
    } finally {
      dispatch({
        type: type.REMOVE_ID_APP_LOADING,
        payload: 'DELETE_DEAL',
      });
    }
  })();
};

export const clearDeleteDealAction = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_DELETE_DEAL,
  });
};

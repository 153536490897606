/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Remove, Add } from '@mui/icons-material';
import dayjs from 'dayjs';
import { increaseDealQtyAction } from '../../actions/dealIncreaseAction';
import useStyles from './DealIncreaseQtyDialogStyles';

const DealIncreaseQtyDialog = ({
  deal,
  increaseDealQtyAction,
  openDialog,
  restId,
  selectedDay,
  setOpenDialog,
  userId,
  userType,
}) => {
  const classes = useStyles();

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const [quantity, setQuantity] = useState(5);
  const [quantityError, setQuantityError] = useState('');
  const [todayOnly, setTodayOnly] = useState(false);

  const isToday = selectedDay === dayjs().weekday();

  const showTodayInput = deal.recurring ? isToday : false;

  const validateForm = () => {
    let formErrors = false;

    if (!quantity || quantity === 0 || quantity > 999) {
      if (quantity > 999) {
        setQuantityError('Please enter a number below 1,000.');
      } else {
        setQuantityError('Please enter a number.');
      }
      formErrors = true;
    } else if (quantityError) {
      setQuantityError('');
    }

    return formErrors;
  };

  const submit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      return;
    }

    increaseDealQtyAction(deal.dealId, quantity, todayOnly, restId, userId, userType);
    closeDialog();
  };

  const formatNumber = (num) => Math.round(num);

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      TransitionProps={{
        onEnter: () => {
          setQuantity(5);
          setQuantityError('');
          setTodayOnly(false);
        },
      }}
    >
      <DialogTitle>Increase {deal.discount} Deal Quantity</DialogTitle>
      <DialogContent>
        <form id='edit-deal-form' noValidate autoComplete='off' onSubmit={submit}>
          <FormControl fullWidth className={classes.formControl} error={Boolean(quantityError)}>
            <InputLabel htmlFor='quantity'>Increase Deals by</InputLabel>
            <OutlinedInput
              id='quantity'
              value={quantity}
              placeholder='5'
              onChange={(e) => setQuantity(Number(e.target.value))}
              onBlur={(e) => setQuantity(formatNumber(e.target.value))}
              type='number'
              className={classes.removeAppearance}
              inputProps={{
                style: {
                  textAlign: 'center',
                },
                min: 1,
                max: 999,
              }}
              startAdornment={
                <InputAdornment position='start'>
                  <IconButton
                    size='small'
                    onClick={() => {
                      setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
                    }}
                  >
                    <Remove color='primary' />
                  </IconButton>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    size='small'
                    onClick={() => {
                      setQuantity((prev) => (prev < 999 ? prev + 1 : 999));
                    }}
                  >
                    <Add color='primary' />
                  </IconButton>
                </InputAdornment>
              }
            />
            {Boolean(quantityError) && <FormHelperText>{quantityError}</FormHelperText>}
          </FormControl>
          {showTodayInput && (
            <FormControl variant='standard' fullWidth>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={todayOnly}
                      name='todayOnly'
                      onChange={(e) => setTodayOnly(e.target.checked)}
                    />
                  }
                  label='Increase for today only'
                />
              </FormGroup>
            </FormControl>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} variant='outlined' color='secondary'>
          Cancel
        </Button>
        <Button form='edit-deal-form' onClick={submit} type='submit' autoFocus>
          Increase Quantity
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  selectedDay: state.dealsByDay.selectedDay,
  restId: state.restaurantActive.restaurant.objectId,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ increaseDealQtyAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DealIncreaseQtyDialog);

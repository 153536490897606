import React from 'react';

// Routes
import {
  RunSheet,
  Settings,
  Floorplan,
  Timeline,
  EditFloorplan,
  Integrations,
  Guestbook,
  MarketPlaceIntegrations,
  Calendar,
} from '@eatclub-apps/ec-bookings-library';
import { Redirect } from 'react-router-dom';
import Analytics from '../components/analytics/Analytics';
import Logout from '../components/auth/Logout';
import Billing from '../components/billing/Billing';
import Bookings from '../components/bookings/Bookings';
import CreateDeal from '../components/deals/createDeal/CreateDeal';
import Deals from '../components/deals/Deals';
import EditDeal from '../components/deals/editDeal/EditDeal';
import EditOffer from '../components/deals/editOffer/EditOffer';
import BookingsLanding from '../components/liveBookings/BookingsLanding/BookingsLanding';
import BookingsLandingPageTitle from '../components/liveBookings/BookingsLanding/BookingsLandingPageTitle';
import Menus from '../components/menus/Menus';
import NotFound from '../components/notFound/NotFound';
import AllOffers from '../components/offers/AllOffers/AllOffers';
import CreateGoal from '../components/offers/CreateGoal/CreateGoal';
import EditGoal from '../components/offers/EditGoal/EditGoal';
import ViewGoal from '../components/offers/ViewGoal/ViewGoal';
import Offers from '../components/offers/OffersDashboard/Offers';
import PreviousGoalsScreen from '../components/offers/PreviousGoalsScreen';
import Support from '../components/support/Support';
import Profile from '../components/profile/Profile';
import Settlements from '../components/settlements/Settlements';
import { BalanceHistory } from '../components/balanceHistory';
import { PayBalance } from '../components/payBalance';
import Insight from '../screens/analytics/Insight';
import CreateOffer from '../components/offers/CreateOffer/CreateOffer';

const routes = [
  // Unused analytics kept in just in case we still need it
  {
    path: '/analytics_old',
    pageTitle: 'Analytics',
    exact: true,
    blockWhiteLabel: false,
    main: () => <Analytics />,
  },

  // Analytics now just redirects to insights
  {
    path: '/analytics',
    pageTitle: 'Analytics',
    exact: true,
    blockWhiteLabel: false,
    ecPayEnabledRequired: false,
    main: () => <Redirect to='/insights' />,
  },
  {
    path: '/insights',
    pageTitle: 'Insight',
    exact: true,
    blockWhiteLabel: false,
    ecPayEnabledRequired: false,
    main: () => <Insight />,
  },
  // {
  //   path: '/deals',
  //   pageTitle: 'Deals',
  //   exact: true,
  //   blockWhiteLabel: true,
  //   main: () => <Deals />,
  // },
  // {
  //   path: '/deals/create',
  //   pageTitle: 'Create Deal',
  //   exact: true,
  //   blockWhiteLabel: true,
  //   backNavigation: {
  //     label: 'Back',
  //     route: '/offers',
  //   },
  //   main: () => <CreateDeal />,
  // },
  {
    path: '/deals/edit/:dealId',
    pageTitle: 'Edit Deal',
    exact: false,
    blockWhiteLabel: false,
    main: () => <EditDeal />,
  },
  {
    path: '/offers',
    pageTitle: 'Exclusive offers',
    exact: true,
    blockWhiteLabel: true,
    main: () => <Offers />,
  },
  {
    path: '/offers/active',
    pageTitle: 'All offers',
    exact: true,
    blockWhiteLabel: true,
    backNavigation: {
      label: 'Back',
      route: '/offers',
    },
    main: () => <AllOffers />,
  },
  {
    path: '/offers/create',
    pageTitle: 'Create new offers',
    exact: true,
    blockWhiteLabel: true,
    backNavigation: {
      label: 'Back',
      route: '/offers',
    },
    main: () => <CreateOffer />,
  },
  {
    path: '/offers/edit/:dealId',
    pageTitle: 'Edit Offer',
    exact: false,
    blockWhiteLabel: false,
    backNavigation: {
      label: 'Back',
      route: '/offers',
    },
    main: () => <EditOffer />,
  },
  {
    path: '/goals/previous',
    pageTitle: 'Previous Goals',
    exact: true,
    blockWhiteLabel: false,
    backNavigation: {
      label: 'Back to offers',
      route: '/offers',
    },
    main: () => <PreviousGoalsScreen />,
  },
  {
    path: '/goals/create',
    pageTitle: '',
    exact: true,
    blockWhiteLabel: false,
    main: () => <CreateGoal />,
  },
  {
    path: '/goals/edit/:goalId',
    pageTitle: 'Edit Goal',
    exact: false,
    blockWhiteLabel: false,
    backNavigation: {
      label: 'Back',
      route: '/offers',
    },
    main: () => <EditGoal />,
  },
  {
    path: '/goals/:goalId',
    pageTitle: '',
    exact: false,
    blockWhiteLabel: false,
    backNavigation: {
      label: 'Back',
      route: '/offers',
    },
    main: () => <ViewGoal />,
  },
  {
    path: '/transactions',
    pageTitle: 'Transactions',
    exact: false,
    blockWhiteLabel: false,
    main: () => <Bookings />,
  },
  {
    path: '/settlements',
    pageTitle: 'Settlements',
    exact: true,
    blockWhiteLabel: false,
    ecPayEnabledRequired: true,
    main: () => <Settlements />,
  },
  {
    // todo: add in redirect from billing to settlements when ecpay is enabled for all regions
    path: '/billing',
    pageTitle: 'Billing & Invoices',
    exact: false,
    blockWhiteLabel: false,
    main: () => <Billing />,
  },
  {
    path: '/settlements/historical-statements',
    pageTitle: 'Billing & Invoices',
    exact: true,
    blockWhiteLabel: false,
    blockPartners: true,
    main: () => <Billing />,
  },
  {
    path: '/settlements/balance-history',
    pageTitle: 'Balance history',
    exact: true,
    blockWhiteLabel: false,
    ecPayEnabledRequired: true,
    main: () => <BalanceHistory />,
    backNavigation: {
      label: 'Back',
      route: '/settlements',
    },
  },
  {
    path: '/settlements/pay-balance',
    pageTitle: '',
    exact: true,
    blockWhiteLabel: false,
    ecPayEnabledRequired: true,
    main: () => <PayBalance />,
    backNavigation: {
      label: 'Back',
      route: '/settlements/balance-history',
    },
  },
  {
    path: '/support',
    pageTitle: 'Support',
    exact: false,
    blockWhiteLabel: false,
    main: () => <Support />,
  },
  {
    path: '/menus',
    pageTitle: 'Menus',
    exact: true,
    blockWhiteLabel: false,
    main: () => <Menus />,
  },
  {
    path: '/livebookings',
    pageTitle: 'EatClub Bookings',
    exact: true,
    blockWhiteLabel: false,
    customPageTitle: () => <BookingsLandingPageTitle />,
    main: () => <BookingsLanding />,
  },
  {
    path: '/livebookings/runsheet',
    pageTitle: 'Run sheet',
    id: 'runsheet',
    exact: true,
    blockWhiteLabel: false,
    header: 'obee',
    obeeEnabledRequired: true,
    main: () => <RunSheet />,
  },
  {
    path: '/livebookings/monthly',
    pageTitle: 'Monthly',
    id: 'monthly',
    exact: true,
    blockWhiteLabel: false,
    header: 'obee',
    obeeEnabledRequired: true,
    main: () => <Calendar />,
  },
  {
    path: '/livebookings/floorplan',
    pageTitle: 'Floor Plan',
    id: 'floorplan',
    exact: true,
    blockWhiteLabel: false,
    header: 'obee',
    obeeEnabledRequired: true,
    obeePremiumRequired: true,
    main: () => <Floorplan />,
  },
  {
    path: '/livebookings/floorplan/edit',
    pageTitle: 'Edit Floor Plan',
    id: 'editFloorplan',
    exact: true,
    blockWhiteLabel: false,
    header: 'custom',
    hideNavigation: true,
    forceHideNavigation: true, // No option to show on this screen
    obeeEnabledRequired: true,
    main: () => <EditFloorplan />,
  },
  {
    path: '/livebookings/timeline',
    pageTitle: 'Timeline',
    id: 'timeline',
    exact: true,
    blockWhiteLabel: false,
    header: 'obee',
    obeeEnabledRequired: true,
    obeePremiumRequired: true,
    main: () => <Timeline />,
  },
  {
    path: '/livebookings/guestbook',
    pageTitle: 'Guest book',
    id: 'guestbook',
    exact: true,
    blockWhiteLabel: false,
    header: 'obee',
    obeeEnabledRequired: true,
    obeePremiumRequired: true,
    main: () => <Guestbook />,
  },
  // {
  //   path: '/livebookings/calendar',
  //   pageTitle: 'Calendar',
  //   exact: true,
  //   blockWhiteLabel: false,
  //   header: 'obee',
  //   main: () => <Calendar />,
  // },
  {
    path: '/livebookings/settings',
    pageTitle: 'Settings',
    exact: true,
    blockWhiteLabel: false,
    header: 'custom',
    hideNavigation: true,
    obeeEnabledRequired: true,
    forceHideNavigation: true,
    main: () => <Settings />,
  },
  {
    path: '/livebookings/integrations/fbmarketplace',
    pageTitle: 'Bookings integrations',
    exact: true,
    blockWhiteLabel: false,
    header: 'custom',
    hideNavigation: true,
    obeeEnabledRequired: true,
    forceHideNavigation: true,
    main: () => <MarketPlaceIntegrations />,
  },
  {
    path: '/livebookings/integrations',
    pageTitle: 'Bookings integrations',
    exact: true,
    blockWhiteLabel: false,
    header: 'custom',
    hideNavigation: true,
    obeeEnabledRequired: true,
    forceHideNavigation: true,
    main: () => <Integrations />,
  },
  {
    path: '/logout',
    pageTitle: 'Logout',
    blockWhiteLabel: false,
    header: 'custom',
    main: () => <Logout />,
  },
  {
    path: '/profile',
    pageTitle: 'Profile',
    blockWhiteLabel: true,
    main: () => <Profile />,
  },
  {
    path: '*',
    pageTitle: 'Not Found',
    exact: false,
    blockWhiteLabel: false,
    main: () => <NotFound />,
  },
];

export default routes;

import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
// import { useAnalytics } from 'use-analytics';
import { fetchActiveOffersForRestaurantAction } from '../../../actions/activeOffersForRestaurantAction';
import { fetchDailyOpposAction } from '../../../actions/dealDailyOpposAction';
import { fetchGoalsAction } from '../../../actions/goalsAction';
import { goalsPropTypes } from '../../../data/models/Goal';
import { COLORS } from '../../../EatClubTheme';
import { opportunitiesPropTypes } from '../../../reducers/dealDailyOpposReducer';
import { offersPropTypes } from '../../../reducers/generatedOffersReducer';
import { trackEvent, usePageViews } from '../../../utils/analytics';
import { eatClubDayOfWeekToDayjs, getDayOccurrences, getDayOfWeek } from '../../../utils/helpers';
import GoalAnalytics from '../GoalAnalytics/GoalAnalytics';
import OfferCard from '../OfferCard/OfferCard';
import PostOppoModal from '../Modals/PostOppoModal';
import TopOpportunities from './TopOpportunities';
import RevenueBanner from '../RevenueBanner/RevenueBanner';
import useStyles from './OffersStyles';
import { ConditionalWrapper } from '../../ConditionalWrapper';
import { Heading } from '../../Heading';
import { Text } from '../../Text';

const Offers = ({
  fetchGoals,
  restId,
  goals,
  fetchDailyOppos,
  fetchActiveOffersForRestaurant,
  userId,
  userType,
  dailyOppos,
  activeOffersForRestaurant,
  restaurantActive,
}) => {
  const muiTheme = useTheme();
  const breakpointSmDown = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const classes = useStyles();
  const history = useHistory();
  const [topOppos, setTopOppos] = useState([]);
  const [repeatDays, setRepeatDays] = useState({}); // How many times each day repeats
  usePageViews();
  // const { track } = useAnalytics();

  // Fetch goals
  useEffect(() => {
    fetchGoals(restId, userId, userType);
  }, [fetchGoals, restId, userId, userType]);

  // Fetch oppos
  useEffect(() => {
    fetchDailyOppos(restId, userId, userType);
  }, [fetchDailyOppos, restId, userId, userType]);

  // Fetch active offers
  useEffect(() => {
    fetchActiveOffersForRestaurant(restId, userId, userType);
  }, [fetchActiveOffersForRestaurant, restId, userId, userType]);

  // Store daily oppos in state, so they can be marked as complete
  useEffect(() => {
    setTopOppos(dailyOppos?.data);
  }, [dailyOppos]);

  // Filter fetched goals to active
  const activeGoal = goals.data.find((goal) => goal.status === 'active');

  const currentDayOfWeek = getDayOfWeek(dayjs());

  const currentlyActiveOffers = activeOffersForRestaurant.data
    .filter((offer) => offer?.status === 'active')
    .sort((a, b) => (a?.startTime >= b?.startTime ? 1 : -1));

  const offersForDay = activeOffersForRestaurant.data
    .filter(
      (offer) =>
        offer?.status !== 'active' &&
        eatClubDayOfWeekToDayjs(offer?.dayOfWeek) === currentDayOfWeek,
    )
    .sort((a, b) => (a?.startTime >= b?.startTime ? 1 : -1));

  const activeOffers = [...currentlyActiveOffers, ...offersForDay];

  // Recalculate the amount of times a deal appears
  useEffect(() => {
    setRepeatDays(getDayOccurrences(dayjs(), activeGoal?.endDate));
  }, [activeGoal]);

  const isLoading = goals.pending || activeOffersForRestaurant.fetching; // note: to make sure all elements 'load' at the same time

  // partySizeLower
  // partySizeUpper
  // revenueExpected

  const { partySizeLower, partySizeUpper, revenueExpected } = activeOffers.reduce(
    (acc, activeOffer) => ({
      partySizeLower: acc.partySizeLower + activeOffer.partySizeLower,
      partySizeUpper: acc.partySizeUpper + activeOffer.partySizeUpper,
      revenueExpected: acc.revenueExpected + activeOffer.revenueExpected,
    }),
    {
      partySizeLower: 0,
      partySizeUpper: 0,
      revenueExpected: 0,
    },
  );

  return (
    <Box className={classes.container}>
      {restaurantActive?.closedEarlyToday && (
        <Box
          style={{
            padding: '20px',
            borderRadius: '16px',
            backgroundColor: COLORS.OFFER_CARD_STATUS_BAR_ACTIVE,
          }}
        >
          Notice: Offers for this restaurant are temporarily disabled
        </Box>
      )}

      <Box
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '48px',
        }}
      >
        {!restaurantActive?.closedEarlyToday && (
          <Box className={classes.loadingContainer}>
            <ConditionalWrapper
              condition={isLoading}
              // eslint-disable-next-line react/no-unstable-nested-components
              wrapper={(children) => (
                <Skeleton variant='rounded' style={{ borderRadius: '32px' }}>
                  {children}
                </Skeleton>
              )}
            >
              {activeGoal ? <GoalAnalytics goal={activeGoal} /> : <RevenueBanner />}
            </ConditionalWrapper>
          </Box>
        )}
        <Box>
          <Box
            style={{
              marginBottom: '24px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              flexWrap: 'wrap',
              gap: '12px',
            }}
          >
            <Heading size='3'>Today&apos;s offers</Heading>

            <Button
              onClick={() => {
                trackEvent(`button_click: view_all_offers`);
                history.push('/offers/active');
              }}
              color='secondary'
              variant='text'
              size='large'
              disabled={restaurantActive?.closedEarlyToday}
              endIcon={
                <svg
                  width='19'
                  height='21'
                  viewBox='0 0 19 21'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M0 10.5H18M18 10.5L8.7 1M18 10.5L8.7 20' stroke='black' />
                </svg>
              }
            >
              View all
            </Button>
          </Box>

          {/* <ConditionalWrapper
          condition={isLoading}
          // eslint-disable-next-line react/no-unstable-nested-components
          wrapper={(children) => (
            <Skeleton variant='rounded' width='100%' style={{ borderRadius: '32px' }}>
              {children}
            </Skeleton>
          )}
        >
          <Box
            style={{
              borderRadius: '24px',
              border: '1px solid #EAE1D4',
              display: 'grid',
              gridTemplateColumns: breakpointSmDown ? '1fr' : '1fr 1fr 1fr',
              marginBottom: '16px',
            }}
          >
            <Box
              style={{
                padding: '16px',
                borderRight: breakpointSmDown ? '' : '1px solid #EAE1D4',
                borderBottom: breakpointSmDown ? '1px solid #EAE1D4' : '',
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
              }}
            >
              <Text>Total offers</Text>
              <Text size='xl'>{activeOffers?.length}</Text>
            </Box>
            <Box
              style={{
                padding: '16px',
                borderRight: breakpointSmDown ? '' : '1px solid #EAE1D4',
                borderBottom: breakpointSmDown ? '1px solid #EAE1D4' : '',
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
              }}
            >
              <Text>Total Customers</Text>
              <Text size='xl'>
                {partySizeLower}-{partySizeUpper}
              </Text>
            </Box>
            <Box style={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
              <Text>Total Revenue</Text>
              <Text size='xl'>{formatCurrency(revenueExpected, false)}</Text>
            </Box>
          </Box>
        </ConditionalWrapper> */}

          {isLoading ? (
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Skeleton variant='rounded' height={320} style={{ borderRadius: '32px' }} />
            </Box>
          ) : (
            <Box className={classes.offersList}>
              {!restaurantActive?.closedEarlyToday && activeOffers?.length > 0 ? (
                activeOffers.map((deal, i) => (
                  <>
                    <OfferCard
                      key={deal?.objectId}
                      offer={deal}
                      occurrences={repeatDays?.[deal?.dayOfWeek]}
                    />

                    {i < activeOffers.length - 1 && <Divider style={{ borderColor: '#EDEDED' }} />}
                  </>
                ))
              ) : (
                <Box
                  style={{
                    padding: '16px 24px',
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'center',
                  }}
                >
                  <Text>There are no active offers right now</Text>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={() => {
                      history.push('/offers/create');
                    }}
                    disabled={restaurantActive?.closedEarlyToday}
                  >
                    Create new offer
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>

        {!restaurantActive?.closedEarlyToday && topOppos?.length > 0 && (
          <Box>
            <TopOpportunities opportunities={topOppos} />
          </Box>
        )}

        <PostOppoModal />
      </Box>
    </Box>
  );
};

Offers.propTypes = {
  fetchGoals: PropTypes.func.isRequired,
  restId: PropTypes.string.isRequired,
  goals: goalsPropTypes.isRequired,
  fetchDailyOppos: PropTypes.func.isRequired,
  fetchActiveOffersForRestaurant: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  dailyOppos: opportunitiesPropTypes.isRequired,
  activeOffersForRestaurant: offersPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  goals: state.goals,
  restId: state.restaurantActive.restaurant.objectId,
  restaurantActive: state.restaurantActive.restaurant,
  userId: state.user.userInfo.objectId,
  userType: state.user.userInfo.userType,
  dailyOppos: state.dealDailyOppos,
  postDailyOppo: state.postDailyOppo,
  activeOffersForRestaurant: state.activeOffersForRestaurant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchGoals: fetchGoalsAction,
      fetchDailyOppos: fetchDailyOpposAction,
      fetchActiveOffersForRestaurant: fetchActiveOffersForRestaurantAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
